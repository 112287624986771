import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import {navigate} from 'gatsby';

const useStyles = makeStyles({
    root1: {
        margin: '1.5rem',
        cursor: 'pointer',
        transition: 'width 2s, height 2s, transform .2s!important',
        zIndex: 0,
        borderRadius: '0!important',
        boxShadow: '0px 0px 3px rgb(11 102 194 / 45%)!important',
        '&:hover': {
            boxShadow: '0px 0px 6px rgb(11 102 194 / 65%)!important',
            transform: 'scale(1.02)!important',
        },
        '@media (max-width: 350px)': {
            margin: '1rem 0.4rem!important',
            width: '76%!important',
        },
        '@media (max-width: 400px)': {
            margin: '1rem',
            width: '85%',
        },
    },
    MonthDate: {
        fontFamily: 'Lato!important',
        color: '#585858',
        margin: '1rem 0rem!important',
        '@media (max-width:426px)': {
            margin: '0!important',
        },
    },
    LatoFont_mobile_hide: {
        fontFamily: 'Lato!important',
        color: '#2c2c2c',
        '@media (max-width: 426px)': {
            display: 'none',
        },
    },
    heading: {
        fontFamily: 'Lato!important',
        color: '#000',
        overflow: 'auto',
        cursor: 'pointer',
        fontSize: '1.5rem!important',
        lineHeight: 'normal!important',
    },
})

interface Props {
    title: string
    imageUrl?: string
    date: string
    slug: string
    excerpt: string
    query?: string
}

const BlogCard: React.FC<Props> = ({title, imageUrl, date, slug, excerpt, query,}) => {
    const classes = useStyles()
    function getHighlightedText(text: string, highlight: string | undefined) {
        const parts = text.split(new RegExp(`(${highlight})`, 'gi'))
        if (highlight == undefined) return
        return (
            <span>
        {' '}
                {parts.map((part, i) => (
                    <span
                        key={i}
                        style={
                            part.toLowerCase() === highlight.toLowerCase()
                                ? {backgroundColor: '#9accff'}
                                : {}
                        }
                    >
            {part}
          </span>
                ))}
      </span>
        )
    }

    return (
        <Card className={classes.root1} onClick={() => navigate(slug)}>
            <CardContent>
                {query == '' ? (
                    <Typography variant='subtitle1' component='b' className={classes.heading}>
                        {title}
                    </Typography>
                ) : (
                    <Typography variant='subtitle2' component='b' className={classes.heading}>
                        {getHighlightedText(title, query)}
                    </Typography>
                )}
                <Typography
                    variant='caption'
                    component='p'
                    className={classes.MonthDate}
                >
                    {date}
                </Typography>
                {query == '' ? (
                    <Typography
                        variant='body2'
                        component='p'
                        className={classes.LatoFont_mobile_hide}
                    >
                        {excerpt}
                    </Typography>
                ) : (
                    <Typography
                        variant='body2'
                        component='p'
                        className={classes.LatoFont_mobile_hide}
                    >
                        {getHighlightedText(excerpt, query)}
                    </Typography>
                )}
            </CardContent>
        </Card>
    )
}

export default BlogCard