import React, {useState} from 'react'
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import {graphql} from 'gatsby'
import Helmet from 'react-helmet'
import Header from '../components/Header';
import BlogCard from "../components/BlogCard";
import SEO from '../components/seo';
import Disclaimer from "../components/Disclaimer";
import Footer from "../components/Footer";

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        overflowY: 'auto',
        position: 'fixed',
    },
    cards: {
        overflowY: 'auto',
        height: '90vh',
        marginTop: '80px',
        width: '100%',
    },
    banner: {
        maxWidth: 'none!important',
        padding: '0!important',
        '& > section': {
            position: 'relative',
        },
        '& > section > div': {
            zIndex: '-1',
            backgroundImage: 'url(' + 'https://res.cloudinary.com/agiliti/image/upload/v1654767481/blog/blog_banner.webp' + ')',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            left: '0',
            position: 'absolute',
            right: '0',
            top: '0',
            height: '18rem',
        },
    },
    heading: {
        textAlign: 'center',
        fontSize: '2rem',
        padding: '2rem 0rem 1rem',
        margin: 0,
    },
    Link: {
        fontFamily: 'Lato',
        fontSize: '20px',
    },
    LinkNone: {
        display: 'none',
    },
    grid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(400px, 1fr))',
        '@media (min-width: 430px)': {
            padding: '0rem 0.5rem',
        },
    },
}))

interface Props {
    data: any
}

const HomePage: React.FC<Props> = ({data}) => {
    const POST_PER_PAGE = 100
    const classes = useStyles()
    const {
        allMarkdownRemark: {edges},
    } = data
    const [posts, setPosts] = React.useState<any[]>([]);
    const [page, setPage] = React.useState(1);
    const totalPosts: any[] = edges.filter((edge: { node: { frontmatter: { date: any } } }) => !!edge.node.frontmatter.date);
    const [state, setState] = useState({
        filteredData: [],
        query: '',
    });

    React.useEffect(() => {
        setPosts(
            totalPosts
                .slice(POST_PER_PAGE * (page - 1), page * POST_PER_PAGE)
                .map(edge => (
                    <BlogCard
                        key={edge.node.id}
                        title={edge.node.frontmatter.title}
                        slug={edge.node.frontmatter.slug}
                        //imageUrl={edge.node.frontmatter.imageUrl.publicURL}
                        date={edge.node.frontmatter.date}
                        excerpt={edge.node.excerpt}
                        query={query}
                        //tags={edge.node.frontmatter.tags}
                    />
                ))
        )
    }, [page])

    const {filteredData, query} = state
    const hasSearchResults = filteredData && query !== ''
    const page_title = 'Square Deal Blog: Property tax appeal'

    return (
        <Box className={classes.root}>
            <Helmet>
                <title>$page_title</title>
                <link rel="canonical" href="https://blog.squaredeal.tax/"/>
            </Helmet>
            <SEO title={page_title}/>
            <Header/>
            <Box sx={{
                overflowY: 'auto',
                height: '90vh',
                marginTop: '80px',
                width: '100%',
            }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box sx={{textAlign: "center"}}>
                            <h1 className={classes.heading}>Lower Your Property Tax!</h1>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{
                            display: 'grid',
                            gridTemplateColumns: 'repeat(auto-fit, minmax(400px, 1fr))',
                            '@media (min-width: 430px)': {
                            padding: '0rem 0.5rem',
                            },
                        }}>{posts}</Box>
                    </Grid>
                    <Grid item xs={12}><Disclaimer/></Grid>
                    <Grid item xs={12}><Footer/></Grid>
                </Grid>
            </Box>
        </Box>
    )
}

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            slug
            title
            description
          }
        }
      }
    }
  }
`

export default HomePage
